@font-face {
  font-family: 'serverless';
  src: url('./fonts/serverless-regular.woff2') format('woff2'), url('./fonts/serverless-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100% !important;
  margin: 0;
  padding: 0;
  line-height: normal !important;
  background: var(--mainBg);
  font-family:  Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 20px !important;
  color: #000000;
  word-wrap: break-word;
  letter-spacing: 0.25px;
}

html, body {
  background: #ffffff;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

.container {
  flex-direction: column;
  height: 100% !important;
  width: 100%;
  margin: 0;
  padding: 10px 10px 50px 10px;
}

.hero {
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 40vh;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}

.hero img {
  max-height: 40vh;
  pointer-events: none;
}

.tagline {
  align-items: center;
  height: auto;
  margin-top: 40px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}

.buttonContainer {
  height: auto;
  text-align: right;
  margin-top: 0;
}

.button {
  flex-direction: row;
  height: 100px;
  width: 400px;
  border-radius: 4px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  font-size: 26px;
  color: #ffffff;
  transition: all ease 200ms;
}

.button:hover {
  cursor: pointer;
  border: 3px solid rgba(255, 255, 255, 1);
}

.button:active {
  border-color: rgba(255, 255, 255, 0.3);
}

.buttonInner {
  flex-direction: row;
  align-items: center;
  height: auto;
  width: 100%;
}

.buttonLeft {
  align-items: flex-end;
  padding-top: 30px;
  padding-right: 20px;
  color: #fd5750;
  font-size: 62px;
  user-select: none;
}

.buttonRight {
  align-items: flex-start;
  padding-top: 36px;
  color: #ffffff;
  font-size: 20px;
  user-select: none;
}

.templateLi {
  font-size: 14px;
  float: left;
}

.templatesUl {
  list-style-type: none;
  margin-bottom: 20px;
  margin-top: 60px;
}

.templatesItem {
  padding-top: 27px;
  height: 50px;
  padding: 13px 30px;
  border-bottom: 1px dotted #e3e2e2;
}

.templatesHeader {
  padding-left: 3%;
  font-size: 22px;
  padding-top: 14px;
  border-bottom: 1px solid rgb(223, 217, 217);
  height: 60px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  width: 100%;
}

#templatesHeaderText {
  padding-left: 7%;
}

.templatesHeader>span {
  display: inline-block;
}

.hidden {
  display: none;
}

.showingTemplatesList {
  display: block;
}

.buttonDisabled {
  background-color: rgb(224 224 224) !important;
  color: #797979 !important;
}

.disabled {
  color: #797979;
}

/**
 * Animations
 */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.titleForm {
  color: grey;
  font-weight: normal;
  margin-bottom: 21px;
  border-bottom: 2px solid #eeebeb;
  padding-bottom: 14px;
}

.bold {
  font-weight: bold !important;
}

.customerContainer {
  position: relative
}

.createCustomer {
  background-color: #2974b2;
  border-radius: 50%;
  line-height: 25px !important;
  padding: 0px 5px 0px 5px;
  text-decoration: none;
  color: white;
  font-size: 23px;
  display: inline-block;
  text-align: center;
  position: absolute;
  margin-top: 7px;
  text-decoration: none;
  height: 28px;
  width: 28px;
}

.createCustomer:hover {
  color: white;
  text-decoration: none;
}

.form-control.customerSelect, .form-control.placeHolderInput, .form-control#phone-number, .form-control#autocenter, .form-control#newPhone, .form-control.placeHolderInput {
  display: inline-block!important;
  margin-right: 5px;
  display: block;
  border: none;
  border-radius: 0!important;
  padding-left: 0!important;
}

.form-control#autocenter {
  display: none!important;
}

.form-control.customerSelect {
  width: 89%!important;
  border-bottom: 1px solid black;
  margin-left: 37px
}

.form-control#phone-number, .form-control#newPhone {
  width: 67%;
  display: inline-block!important;
  margin-left: 2%;
  vertical-align: top;
  border-bottom: 1px solid #ccc;
  position: absolute;
  right: 0;
}

.form-control.placeHolderInput {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.form-control#autocenter {
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
}

.form-control.placeHolderInput:focus, .form-control.customerSelect:focus, .form-control#phone-number:focus, .form-control#autocenter:focus, .form-control#message:focus {
  border-width: 2px!important;
  border-color: #2974b2;
  box-shadow: none!important;
}

#message {
  border-radius: 0;
}

.customerSelect::placeholder {
  color: #ccc;
}

.right {
  float: right;
  text-decoration: none;
}

.top-left, .top-right {
  border-radius: 0!important;
  width: 49%;
  font-size: 16px!important;
}

.top-right {
  background-color: #579fdc!important;
  border: none;
}

.top-left {
  float: left;
  background-color: white!important;
  color: #579fdc!important;
  border-color: #579fdc!important;
}

.top-left:hover {
  background-color: rgb(235, 235, 235)!important;
}

.top-right {
  float: right;
}

.loadingMessageApp {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.attachmentLi {
  font-size: 12px;
  margin-left: -10px;
}

.attachmentItem {
  margin-bottom: 4px;
  border-bottom: 1px dotted #ddd;
  padding-bottom: 10px;
}

.attachmentItem:last-child {
  border: none;
}

/* menu flags */

.menu-flags {
  font-size: 1rem;
  padding: 0;
  margin-right: 1%;
}

.menu-flags button {
  font-size: 1rem!important;
  padding: 1px 2px 1px 0;
  border: none;
  border-bottom: 1px solid #ccc!important;
  border-radius: 0;
}

.menu-flags ul {
  width: 100%!important;
  max-height: none!important;
}

#sendMessage, #sendCreateUser, .reloadBtn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: border-color 0.25s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s, background-color 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;
  margin: 0px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: inherit;
  font-weight: 400 !important;
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box;
  user-select: none;
  padding: 0px 1.07143em;
  height: 40px;
  line-height: 38px !important;
  font-size: 14px;
  background-color: rgb(31, 115, 183);
  color: rgb(255, 255, 255);
}

.hidden {
  display: none;
}

#modalLimitAttachments, #modalMessage {
  height: auto;
  position: absolute;
  text-align: center;
  border-radius: 3px;
  top: 20px;
  left: 0px;
  z-index: 999;
  width: 96%;
  margin-left: 2%;
}

.row {
  width: 100%;
}

.attachmentUlModal {
  margin-left: 8px;
  text-align: left;
  margin-top: 15px;
}

.attachmentLi {
  margin-top: 7px;
}

.removeAttachment {
  display: inline-block;
  border: 2px solid grey;
  width: 20px;
  height: 20px;
  line-height: 14px !important;
  text-align: center;
  border-radius: 50%;
  color: grey;
  margin-top: 5px;
}

.btnCancelModal, .btnCancelModalError {
  transition: border-color 0.25s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s, background-color 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;
  margin: 0px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: inherit;
  font-weight: 400 !important; 
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box;
  user-select: none;
  padding: 0px 1.07143em;
  width: 180px;
  line-height: 38px !important;
  font-size: 16px;
}

.btnCancelModal {
  background-color: rgb(31, 115, 183);
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  margin-top: 10px;
}

.btnCancelModalError {
  margin-bottom: 5px;
  margin-top: 30px;
}

.attachmentList {
  width: 50%;
}

#formFile, #formFile button {
  display: "none"
}

.searchUserList.show {
  position: absolute;
  z-index: 99;
  background-color: white;
  border: 1px solid #ddd;
  width: 90%;
  padding: 10px 20px;
  left: 35px;
  max-height: 350px;
  overflow: auto;
  margin-top: 2px
}

.searchUserList.hide {
  display: none;
}

.searchUserList .user-item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 10px;
}

.searchUserList .user-item:last-child {
  border: none;
}

.searchUserList .avatar {
  display: inline-block;
  background-color: rgb(101, 101, 253);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  line-height: 33px !important;
  font-weight: bold !important;
  color: white;
  font-size: 18px;
}

.searchUserList .user-item.withoutPhone .avatar {
  background-color: rgb(205 205 205);
}

.searchUserList .user-item.withoutPhone .userDetails {
  color: grey;
  font-style: italic;
}

.searchUserList .userDetails {
  display: inline-block;
  vertical-align: top;
}

.country {
  position: relative;
}

.createUser {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 999;
  padding: 10px;
}

.createUser .templatesHeader {
  position: initial;
}

.createUser .templatesHeader {
  position: initial;
}

#newRequester {
  margin-top: 20px;
  width: 100%!important;
  margin-left: 0;
}

.textloader {
  position: absolute;
  top: 50%;
  right: 5%;
}

.telloader {
  position: absolute;
  top: 40%;
  right: 5%;
}

#divSendingLoader {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 3%;
  z-index: 5;
}

.errorField, .errorFieldFlags button {
  border-bottom: 1px solid #ff6c6c!important;
}

.errorText {
  text-align: left;
  display: block;
  font-style: italic;
  color: red;
  font-size: 13px;
}

.phoneInputs {
  margin-top: 20px;
}

.form-label {
  padding: 0 20px 5px 0;
}

.blockedPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: rgb(171, 168, 168);
  width: 100%;
  height: 100%;
  min-height: 100vh !important;
  z-index: 4;
}

.messageModal {
  z-index: 5;
}

.removeIcon {
  width: 40px!important;
  margin-right: 0px!important;
}

.attachmentName {
  max-width: 290px!important;
  width: 100%!important;
}

.descriptionMessage {
  margin-top: 10px;
  font-size: 13px;
}

#rfs-btn {
  background-color: #ffffff;
}

#rfs-btn::after {
  content: none;
}

.reloadBtn {
  margin-top: 21px;
  width: 80%;
}

.sticky {
  position: fixed !important;
  top: 20px !important;
}

#root div .container .customerContainer, #root div .container .country, #root div .container .messageText {
  margin-bottom: 11px!important;
}

#root div .container .attachfile {
  margin-bottom: 4px!important;
}

.tokenMessage {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
}

.tokenMessage span {
  display: block;
  background-color: white;
  margin-top: 20px;
  width: 70%;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  margin: 30px auto;
}

.selectChannel {
  margin-top: 20px;
  margin-bottom: 20px;
}

.radioBtn {
  display: inline-block;
  margin-right: 30px;
}

.radioBtn input[type=radio] {
  margin-right: 10px;
}

#placeHoldersList {
  text-align: center;
}

.placeHolderDiv {
  margin-top: 15px;
  margin-bottom: 15px;
}

.category{
  color: #797979;
  float: right;
  font-size: 120%;
} 